<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <b-card no-body>
      <b-card-body>
        <div class="h4 mb-1">
          รายชื่อแอดมิน
        </div>

        <div class="d-flex flex-wrap">
          <div class="d-flex align-items-start py-50">
            <div class="btn-disable">
              วันที่(จาก)
            </div>
            <flat-pickr
              v-model="dateStart"
              class="form-control"
              placeholder="เลือกเวลาเริ่มต้น"
            />
          </div>

          <div class="d-flex align-items-start py-50">
            <div class="btn-disable">
              วันที่(ถึง)
            </div>
            <flat-pickr
              v-model="dateEnd"
              class="form-control"
              placeholder="เลือกเวลาเริ่มต้น"
            />
          </div>

          <!-- <div class="d-flex align-items-start py-50">
            <div class="btn-disable">
              ประเภท
            </div>
            <b-form-select v-model="search_type" :options="search_type_option" placeholder="เลือกประเภทที่จะค้นหา" />
          </div> -->

          <!-- <div class="d-flex align-items-start py-50">
            <div class="btn-disable">
              คำค้นหา
            </div>
            <input
              id="username"
              v-model="search_val"
              type="text"
              class="form-control"
              style="width: 230"
              placeholder="กรอกข้อมูล"
            >
          </div> -->

          <div class="d-flex align-items-start py-50">
            <button
              class="btn btn-primary ml-lg-25"
              @click="getAdmin()"
            >
              ค้นหา
            </button>

            <!-- <button
              v-b-modal.exportDepositModal
              class="btn btn-warning ml-25"
            >
              ออกรายงาน EXCEL
            </button> -->
          </div>
          <b-modal
            id="exportDepositModal"
            title="ระบุรหัสผ่าน"
            @ok="checkExportPassword()"
          >
            <b-form-input v-model="exportPassword" />
          </b-modal>

          <!-- <div class="ml-auto py-50">
            <router-link :to="{ name: 'member-add' }">
              <button
                class="btn btn-primary"
                @click="getUsers()"
              >
                CREATE
              </button>
            </router-link>
          </div> -->
        </div>

        <div class="mt-3 d-flex justify-content-between align-items-center">
          <div class="row no-gutters align-items-center">
            <div class="col-6">
              <!-- <h4 class="mb-0">
                ยอดสมาชิก {{ totalRows }}
              </h4> -->
            </div>
          <!-- <div class="col-6 d-flex justify-content-end">
              <input
                id="username"
                v-model="search_val"
                type="text"
                class="form-control"
                style="width: 150px;"
                placeholder="ค้นหา"
              >
            </div> -->
          </div>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>
        <!-- <template #cell(roleshow)="data">
        <p v-if="data.item.role === 2">
          super admin
        </p>
        <p v-if="data.item.role === 3">
          super admin
        </p>
        <p v-if="data.item.role === 4">
          theme admin
        </p>
        <p v-if="data.item.role === 5">
          marketing
        </p>
        <p v-if="data.item.role === null">
          admin
        </p>
      </template> -->

        <template #cell(time)="data">
          <p v-if="data.item.admintime">
            {{ data.item.admintime }} น.
          </p>
          <p v-else>
            ยังไม่ได้ตั้งเวลาทำงาน
          </p>
        </template>

        <template #cell(status)="data">
          <span
            v-if="data.item.online === 0"
            class="badge badge-glow badge-success"
          > Online</span>

          <span
            v-else
            class="badge badge-glow badge-danger"
          > Offline </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap d-flex align-items-center">
            <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'dashboard', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          /> -->
            <i
              v-if="data.item.online === 0 && (permisedit == true || UserData.vip === 1)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Kick Off'"
              class="far fa-power-off mr-1"
              @click="closeItem(data.item.id)"
            />

            <feather-icon
              v-if="UserData.vip === 1 || permisedit == true"
              v-b-modal.modal-1
              icon="EditIcon"
              size="16"
              class="mr-1"
              @click="editData(data.item)"
            />
            <feather-icon
              v-if="UserData.vip === 1 || permisedit == true"
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="TrashIcon"
              size="16"
              @click="deleteData(data.item.id)"
            />
            <b-tooltip
              title="Preview Invoice"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions2)="data">
          <div class="text-nowrap d-flex align-items-center">
            <feather-icon
              v-if="permisedit == true"
              :id="`invoice-row-${data.item.id}-preview-icon`"
              v-b-modal.modal-1
              icon="EditIcon"
              size="16"
              class="mr-1"
              @click="editData(data.item)"
            />
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

      <b-modal
        id="modal-1"
        title="แอดมิน"
        ok-title="Submit"
        @ok="submit"
      >
        <b-form-group
          label="ชื่อ"
          label-for="vi-first-name"
        >
          <b-form-input
            id="vi-first-name"
            v-model="name"
            placeholder=""
          />

        </b-form-group>
        <b-form-group
          label="เบอร์โทร"
          label-for="vi-tel"
        >

          <b-form-input
            id="vi-tel"
            v-model="tel"
            placeholder=""
          />

        </b-form-group>
        <b-form-group
          label="username"
          label-for="vi-username"
        >

          <b-form-input
            id="vi-username"
            v-model="username"
            placeholder=""
          />

        </b-form-group>

        <b-form-group
          label="Password"
          label-for="vi-password"
        >
          <b-form-input
            id="vi-password"
            v-model="password"
            placeholder=""
            type="password"
          />
        </b-form-group>

        <b-form-group
          label="Role"
          label-for="vi-role"
        >
          <v-select
            v-model="role"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="itemsRole"
            :reduce="name => name._id"
          />
        </b-form-group>

        <!-- <b-form-group
        label="Line ID"
        label-for="vi-lineuserid"
      >

        <b-form-input
          id="vi-lineuserid"
          v-model="lineuserid"
          placeholder=""
        />

      </b-form-group> -->

        <div>
          <h5>รูป</h5>
          <div class="row px-1">
            <div
              class="col-4 cursor-pointer rounded-lg"
              :class="{ 'bg-primary': avatar === '01.webp' }"
              @click.prevent="selectAvatar('01.webp')"
            >
              <img
                class="w-100"
                src="/images/admin/01.webp"
              >
            </div>
            <div
              class="col-4 cursor-pointer rounded-lg"
              :class="{ 'bg-primary': avatar === '02.webp' }"
              @click.prevent="selectAvatar('02.webp')"
            >
              <img
                class="w-100"
                src="/images/admin/02.webp"
              >
            </div>
            <div
              class="col-4 cursor-pointer rounded-lg"
              :class="{ 'bg-primary': avatar === '03.webp' }"
              @click.prevent="selectAvatar('03.webp')"
            >
              <img
                class="w-100"
                src="/images/admin/03.webp"
              >
            </div>
            <div
              class="col-4 cursor-pointer rounded-lg"
              :class="{ 'bg-primary': avatar === '04.webp' }"
              @click.prevent="selectAvatar('04.webp')"
            >
              <img
                class="w-100"
                src="/images/admin/04.webp"
              >
            </div>
            <div
              class="col-4 cursor-pointer rounded-lg"
              :class="{ 'bg-primary': avatar === '05.webp' }"
              @click.prevent="selectAvatar('05.webp')"
            >
              <img
                class="w-100"
                src="/images/admin/05.webp"
              >
            </div>
            <div
              class="col-4 cursor-pointer rounded-lg"
              :class="{ 'bg-primary': avatar === '06.webp' }"
              @click.prevent="selectAvatar('06.webp')"
            >
              <img
                class="w-100"
                src="/images/admin/06.webp"
              >
            </div>
          </div>
        </div>
      <!-- {{ starttime }} -->
      <!-- <b-form-group
        label="เวลาเริ่มงาน"
        label-for="vi-first-name"
      >
        <b-form-timepicker
          id="timepicker-placeholder"
          v-model="starttime"
          placeholder="Choose a time"
          local="th"
        />
      </b-form-group>
      <b-form-group
        label="เวลาเริ่มเลิกงาน"
        label-for="vi-first-name"
      >
        <b-form-timepicker
          id="timepicker-placeholder"
          v-model="endtime"
          placeholder="Choose a time"
          local="th"
        />
      </b-form-group> -->
      </b-modal>

      <b-modal
        id="modal-role"
        title="Role"
        hide-footer
        size="lg"
      >
        <div class="pb-0 d-flex align-start">
          <div class="row no-gutters">
            <div class="row">
              <div class="d-flex col col-12">
                <div class="d-flex align-items-start">
                  <div class="btn-disable">
                    ชื่อ ROLE
                  </div>
                </div>
                <input
                  v-model="rolename"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
          </div>

          <div
            class="row no-gutters"
            style="justify-content: end"
          >
            <button
              class="btn btn-primary"
              @click="addrole()"
            >
              สร้าง
            </button>
          </div>
        </div>

        <b-table
          striped
          hover
          responsive
          class="position-relative items-center mt-2"
          :items="itemsRole"
          :fields="fieldRole"
        >
          <template #cell(permission)="data">
            <button
              class="btn btn-warning"
              @click="permissModal(data.item)"
            >
              Permission
            </button>
          </template>

          <template #cell(delete)="data">
            <button
              class="btn btn-outline-danger text-danger"
              @click="deleteRole(data.item._id)"
            >
              <i class="fas fa-trash mr-50" /> ลบ
            </button>
          </template>
        </b-table>
      </b-modal>

      <b-modal
        ref="modal-permission"
        title="Permission"
        scrollable
        hide-footer
      >
        <b-table
          striped
          hover
          responsive
          class="position-relative items-center mt-2"
          :items="itemsPermission"
          :fields="visiblePermission"
        >
          <template #cell(permission)="data">
            <b-form-checkbox
              v-model="data.item.permission"
              class="custom-control-primary"
              @change="changePermission(itemsPermission)"
            />
          </template>

          <template #cell(permission_edit)="data">
            <b-form-checkbox
              v-if="data.item.id === 'admin' || data.item.id === 'member' || data.item.id === 'coupon' || data.item.id === 'promotion' || data.item.id === 'updategame' || data.item.id === 'wheelsetting' || data.item.id === 'dailysetting' || data.item.id === 'settingbank' || data.item.id === 'setting'"
              v-model="data.item.permission_edit"
              class="custom-control-primary"
              @change="changePermission(itemsPermission)"
            />
          </template>
        </b-table>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import {
  BFormCheckbox, BTooltip, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination,
  // BInputGroup,
  BFormInput,
  // BButton,
  BCardBody, VBToggle, VBTooltip, BOverlay,
  // BInputGroupAppend,
  // BFormTimepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
    // BFormTimepicker,
    flatPickr,
    vSelect,
    BTooltip,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    // BButton,
    BCardBody,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      show: true,
      rolename: '',
      roleId: '',
      statusStaff: null,
      optionStaff: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'ได้รับยูสเซอร์แล้ว' },
        { value: 2, text: 'ยังไม่ได้รับยูสเซอร์แล้ว' },
      ],
      fieldPermission: [
        { key: 'permission', label: '', show: true },
        { key: 'path', label: 'ชื่อ Path', show: true },
        { key: 'permission_edit', label: 'สิทธิแก้ไข', show: true },
      ],
      dateStart: moment().tz('Asia/Bangkok').startOf('months')
        .format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').endOf('months').format('YYYY-MM-DD'),
      fieldRole: [
        { key: 'name', label: 'ชื่อ Role', show: true },
        { key: 'permission', label: 'Permission', show: true },
        { key: 'delete', label: 'ลบ', show: true },
      ],
      depositdata: [],
      rolelist: [
        { text: 'super admin', value: 2 },
        { text: 'admin', value: null },
        // { text: 'sbo', value: 3 },
        // { text: 'theme admin', value: 4 },
        // { text: 'marketing', value: 5 },
      ],
      itemsRole: [
        { id: 1, role: 'admin' },
        { id: 1, role: 'admin' },
        { id: 1, role: 'admin' },
      ],
      search_type: null,
      search_val: null,
      starttime: '',
      endtime: '',
      role: null,
      adminData: '',
      name: '',
      tel: '',
      username: '',
      lineuserid: '',
      password: '',
      avatar: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.', show: true },
        // { key: 'username', label: 'ยูส', show: true },
        { key: 'name', label: 'ชื่อแอดมิน', show: true },
        // { key: 'tel', label: 'เบอร์โทร', show: true },
        { key: 'rolename', label: 'role', show: true },
        // { key: 'time', label: 'เวลาทำงาน', show: true },
        // { key: 'status', label: 'status', show: true },
        // { key: 'actions', label: 'Action', show: true },
        { key: 'userfirstdep', label: 'ปิดยอดสมัคร (คน)', show: true },
        { key: 'bonus', label: 'โบนัส (บาท)', show: true },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      permisedit: false,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    visibleFields() {
      return this.fields.filter(field => {
        if (field.key === 'actions' && this.UserData.vip !== 1) {
          return false
        }

        if (field.key === 'actions2' && this.UserData.vip === 1 && this.UserData.hide === 1) {
          return false
        }

        return field.show
      })
    },
    visiblePermission() {
      return this.fieldPermission.filter(field => field.show)
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.getAdmin()
    this.permisedit = this.UserData.ability[this.UserData.ability.findIndex(x => x.path === this.$route.path)].edit
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getAdmin() {
      this.show = true
      const params = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .get('/agent/adminfirstdepcheck', { params })
        .then(response => {
          this.onFiltered(response.data)
          this.show = false
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      this.adminData = null
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.starttime = ''
      this.endtime = ''
      this.name = ''
      this.tel = ''
      this.lineuserid = ''
      this.username = ''
      this.password = ''
      this.avatar = ''
      this.role = ''
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // console.log(data)
      // if (data.admintime) {
      //   const time = data.admintime.split('-')
      //   // console.log(time)
      //   // eslint-disable-next-line prefer-destructuring
      //   this.starttime = time ? time[0] : ''
      //   // eslint-disable-next-line prefer-destructuring
      //   this.endtime = time ? time[1] : ''
      // } else {
      //   this.starttime = ''
      //   this.endtime = ''
      // }

      this.adminData = data
      this.name = data.name
      this.tel = data.tel
      this.role = data.role_profile
      this.username = data.username
      this.avatar = data.avatar
      this.lineuserid = data.lineuserid
      this.password = ''
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    Warnings(mes) {
      this.$swal({
        icon: 'warning',
        title: '<h3 style="color: #141414">แจ้งเตือน</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    selectAvatar(val) {
      this.avatar = val
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>
<style lang="scss">
.btn {
  box-shadow: 0 4px 8px -4px rgba(94, 86, 105, .42) !important;
}

.row {
  flex-wrap: wrap;
  flex: 1 1 auto;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
